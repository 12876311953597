// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  base_href: '',

  auth_cookie: 'vnpayuatdiva',
  auth_token: 'vnpayuatdiva',
  api_base_url: 'https://vnpayuat.sbv.vidiva-dev.com/api',

  google_captcha_site_key: '6LcD2bMUAAAAAETA76P_QbxjdeBzVDowFQglw_f1',
  google_captcha_language: 'en', // USE VIETNAMESE

};
